@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorGrey50);
  position: relative;
}

.filters {
  /* border: 1px solid var(--colorGrey100);  */
  & > *:not(:last-child) {
    /* margin-right: 8px; */
    /* margin-bottom: 8px; */
    height: auto;
    /* border-right: 1px solid var(--colorGrey100); */
  }
}
